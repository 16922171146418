import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import {
  tagQuery,
  topicsQuery,
} from 'core/queries/tagTopics';

import { Helmet } from 'core/libs/helmet';

import H1 from 'core/components/H1';
import TagTopics from 'core/components/TagTopics';
import { Indent } from 'core/components/Wrappers';
import { withBreakpoint } from 'core/components/breakpoint';

import { WideWrapper } from 'site/components/Wrappers';
import Boroda from 'site/components/Boroda';
import RubricTopics from 'site/components/RubricTopics';
import Pagination from 'site/components/Pagination';


function TagPage({ location, history, match, isDesktop }) {
  const [
    { data: tag, isLoading: tagIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
  ] = useRequest({ queries: [
    tagQuery({ history, match }),
    topicsQuery({
      location, history, match,
      limit: isDesktop ? 16 : 9,
      fields: 'list_headline,headline,published_at,link',
      include: 'image',
    }),
  ] });

  return (
    <>
      <Indent top={30} bottom={20}>
        <TagTopics
          tag={tag}
          topics={rawTopics}
          isLoading={tagIsLoading || topicsAreLoading}
          limit={isDesktop ? 16 : 9}
          headerSpacing={20}
          titleComponent={({ children }) => (
            <WideWrapper>
              <H1 dataQa='page-title'>{children}</H1>
            </WideWrapper>
          )}
          pagination={paginationProps => (
            <WideWrapper>
              <Pagination {...paginationProps} />
            </WideWrapper>
          )}
        >
          {({ topics, title, descriptionMeta }) => (
            <>
              <Helmet>
                <meta name='description'
                  content={descriptionMeta || `Все статьи, новости и подборки про ${title}. Собрали самые интересные и популярные факты про ${title} для вас.`}
                />
              </Helmet>
              <RubricTopics topics={topics} isLoading={topicsAreLoading} />
            </>
          )}
        </TagTopics>
      </Indent>
      <Boroda />
    </>
  );
}

TagPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(TagPage);
