import resolveRelationships from 'core/utils/relationships';

export default resolveRelationships(['content', 'rubric', 'background'], {}, {
  content: {
    widgets: [],
  },
  background: {
    asset: {
      attributes: {
        versions: {},
      },
    },
  },
});
