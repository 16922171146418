import PropTypes from 'prop-types';

import Render from './Render';
import Wrapper from './Wrapper';


export default function WantToWatch({ selections }) {
  return (
    <Wrapper>
      <Render selections={selections} />
    </Wrapper>
  );
}

WantToWatch.propTypes = {
  selections: PropTypes.arrayOf(PropTypes.object),
};

WantToWatch.displayName = 'WantToWatch';

export { WantToWatch as StorybookComponent };
