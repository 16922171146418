import PropTypes from 'prop-types';
import cx from 'classnames';

import { bebop as bebopApi } from 'core/api/definitions/bebop';

import bindProps from 'core/components/bindProps';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { useFetchAndUpdateWidgets } from 'core/components/GameCompare/utils';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { datePattern } from 'site/constants';
import { dateFormat  } from 'core/utils/dates';

import withTheme from 'core/components/theme';

import SmartImage from 'core/components/SmartImage';
import Link from 'core/components/Link';
import Lightning from 'core/components/GameCompare/Lightning';

import { imageMaxWidthValidator } from 'site/utils/prop-types';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
  'rubric',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'topic_type',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
});

function CardVertical(props) {
  const {
    content,
    size,
    theme,
    imageMaxWidth,
    imageRatio,
    noScale,
  } = props;

  const {
    link,
    headline,
    published_at: publishedAt,
    list_headline: listHeadline,
  } = content?.attributes || {};

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric,
    tags,
  } = relationships(content) || {};

  const widgets = useFetchAndUpdateWidgets(bebopApi, content);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          max-width ${imageMaxWidth}px
      `}</style>
    </scope>
  );

  const categoryText = (tags?.[0]?.attributes || rubric)?.title;
  const withImage = (originalCover || widgets?.length > 0) && imageMaxWidth !== 0;

  return (
    <div
      className={cx(
        styles.card,
        styles[`_size_${size}`],
        noScale && styles._noScale,
      )}
    >
      <style jsx>{`
        .${styles.card}
          max-width ${imageMaxWidth}px

        .${styles.headline}
          color ${theme.colors.primary}
          font-family ${theme.fonts.display}
          :global(.${styles.link}:hover) &
            color ${theme.colors.hoverHeadlineColor}
  
        .${styles.rubric}
        .${styles.time}
        .${styles.tags}
          font 12px/15px ${theme.fonts.text}
  
        .${styles.rubric}
          color ${theme.colors.rubricColor}
  
        .${styles.time}
          color ${theme.colors.timeTextColor}
  
        .${styles.tags}
          color ${theme.colors.tagsTextColor}
      `}</style>
      <Link
        to={link}
        type='secondary'
        className={scoped.wrapClassNames(styles.link)}
      >
        {withImage && (
          <div className={styles.cover}>
            {widgets?.length > 0 ?
              <Lightning
                imageMaxWidth={imageMaxWidth}
                height={215}
                widget={widgets[0]}
                progressType={4}
                lightningColor={theme.colors.primary}
                borderRadius='0'
              />
              :
              <SmartImage
                src={originalCover}
                previewSrc={previewCover}
                maxWidth={imageMaxWidth}
                aspectRatio={imageRatio}
              />
            }
          </div>
        )}
        <div className={styles.infoLine}>
          {publishedAt && <span className={styles.time}>{dateFormat(publishedAt, datePattern)}</span>}
          {categoryText && <span className={tags?.length > 0 ? styles.tags : styles.rubric}>{categoryText}</span>}
        </div>
        {(listHeadline || headline) && (
          <div className={styles.headline}>
            {listHeadline || headline}
          </div>
        )}
      </Link>
      <scoped.styles />
    </div>
  );
}

CardVertical.propTypes = {
  /** Данные карточки */
  content: modelPropTypes(topicAttributes),
  /** Размер карточки */
  size: PropTypes.oneOf(['xs', 's', 'm']),
  /** @ignore */
  theme: PropTypes.object,

  /** Устанавливает максимальную ширину изображения */
  imageMaxWidth: imageMaxWidthValidator,

  /** Устанавливает соотношение сторон изображения */
  imageRatio: PropTypes.number,

  /**
   * Запрещает scale анимацию.
   * Может быть нужно в контексте overflow блока - Drum.
   */
  noScale: PropTypes.bool,
};

CardVertical.defaultProps = {
  size: 's',
  imageRatio: 3 / 2,
};

const Card = withTheme(CardVertical);

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export { Card as CardVerticalS };
export const CardVerticalM = bindProps({ size: 'm' })(Card);
export const CardVerticalXS = bindProps({ size: 'xs' })(Card);

export default Card;

export { CardVertical as StorybookComponent };
