import { useContext } from 'react';
import PropTypes from 'prop-types';


import modelPropTypes from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import Image from 'core/components/Image';
import ImageAmp from 'core/components/SmartImage/Amp';

import MovieMeta from 'site/components/MovieMeta';
import MovieAvailabilityButton from 'site/components/MovieAvailabilityButton';
import { TopicContext } from 'site/pages/topic';

import { replaceNewLineWithComma } from 'site/utils';

const relationships = resolveRelationships(
  ['image', 'movie_meta'],
  {},
  { image: { versions: {} } },
);

function SmartTeaserMovie({ parentProps, block }, { amp }) {
  const { moviesData } = useContext(TopicContext);

  const movie = moviesData[block.id]?.movie;

  if (!movie) return null;

  const { headline } = movie.attributes;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
    movie_meta: {
      release_year: releaseYear,
      duration,
      genres,
      directors,
      actors,
      age_access: ageAccess,
      countries,
      kinopoisk_rating: kinopoiskRating,
      imdb_rating: imdbRating,
      original_title: originalTitle,
    },
  } = relationships(movie);

  const { rubric, theme, isDesktop } = parentProps;

  const {
    colors,
    fonts,
    texts,
  } = theme;

  const hasRatings = kinopoiskRating || imdbRating;
  const hasPersons = directors || actors;

  const meta = {
    releaseYear,
    genres,
    duration,
    countries,
    ageAccess,
  };

  const isSelectionsRubric = rubric.slug === 'selections';
  const directorsFormatted = replaceNewLineWithComma(directors, 5, ' и др.');
  const actorsFormatted = replaceNewLineWithComma(actors, 6, ' и др.');
  const HeadlineWrapper = isSelectionsRubric ? 'h2' : 'div';

  return (
    <div className='teaser'>
      <style jsx>{`
        .teaser
          background ${colors.accent2}

        .headline
          font-family ${fonts.display}

        .originalTitle
          color ${texts.lead.color}
          font ${texts.lead.font}

          :global(.mobile) &
            font 400 14px/1 ${fonts.display}

        .ratings
          color ${colors.active1}

        .persons
          color ${colors.gray2}
      `}
      </style>
      <style jsx>{`
        .teaser
          display grid
          align-items start
          padding 20px
          border-radius 8px
          margin-bottom 44px

          :global(.desktop) &
            gap 20px
            grid-template-columns 2fr 1fr
            grid-template-areas 'info cover'

          :global(.mobile) &
            gap 10px
            grid-template-areas 'cover' 'info'
            margin-left -20px
            margin-right -20px

        .info
          grid-area info

        .headline
          font-size 32px
          line-height 42px
          font-weight 400
          margin 0 0 5px

          :global(.mobile) &
            font-size 22px
            line-height 29px

        .originalTitle
          margin-bottom 10px

        .ratings
          font-size 12px
          line-height 15px
          margin-bottom 10px

          span + span
            margin-left 10px

        .meta
          margin-bottom 10px

        .persons
          font-size 12px
          line-height 18px
          margin-bottom 15px

          span
            display block

        .cover
          border-radius 8px
          overflow hidden
          grid-area cover

        .headline
        .ratings
        .meta
        .persons
          &:last-child
            margin-bottom 0
      `}
      </style>
      <div className='info'>
        <HeadlineWrapper className='headline'>
          {headline}
        </HeadlineWrapper>
        {originalTitle && originalTitle !== headline && (
          <div className='originalTitle'>
            {originalTitle}
          </div>
        )}
        {hasRatings && (
          <div className='ratings'>
            {kinopoiskRating && <span>Рейтинг: {kinopoiskRating}</span>}
            {imdbRating && <span>IMDb: {imdbRating}</span>}
          </div>
        )}
        {Object.keys(meta).length > 0 && (
          <div className='meta'>
            <MovieMeta meta={meta} />
          </div>
        )}
        {hasPersons && (
          <div className='persons'>
            {directorsFormatted && <span>Режиссер: {directorsFormatted}</span>}
            {actorsFormatted && <span>Главные роли: {actorsFormatted}</span>}
          </div>
        )}
        {!isSelectionsRubric && <MovieAvailabilityButton movie={movie} />}
      </div>
      <div className='cover'>
        {amp ? (
          <ImageAmp src={cover} />
        ) : (
          <Image
            src={cover}
            maxWidth={isDesktop ? 260 : 660}
            previewSrc={previewCover}
            instant
          />
        )}
      </div>
    </div>
  );
}

SmartTeaserMovie.contextTypes = {
  amp: PropTypes.bool,
};

SmartTeaserMovie.propTypes = {
  movie: modelPropTypes({
    headline: PropTypes.string,
    source_url: PropTypes.string,
  }),
  block: PropTypes.object,
  parentProps: PropTypes.object,
};

export default SmartTeaserMovie;
