import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import {
  rawRubricQuery,
  rawTopicsQuery,
} from 'core/queries/rubric';

import { withBreakpoint } from 'core/components/breakpoint';

import H1 from 'core/components/H1';
import Rubric from 'core/components/Rubric';
import Page from 'core/components/Page';
import EmptyWrapper from 'core/components/EmptyWrapper';
import { Indent } from 'core/components/Wrappers';

import { isGameCompare } from 'core/components/GameCompare/utils';

import { WideWrapper } from 'site/components/Wrappers';
import RubricTopics from 'site/components/RubricTopics';
import Boroda from 'site/components/Boroda';
import Pagination from 'site/components/Pagination';


function RubricPage(props) {
  const {
    location,
    location: {
      pathname,
    },
    match,
    history,
    isDesktop,
  } = props;

  const isGameComparePage = isGameCompare(pathname);

  const [
    { data: rawRubric, isLoading: rubricIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
  ] = useRequest({ queries: [
    rawRubricQuery({ match, history }),
    rawTopicsQuery({
      location,
      match,
      history,
      limit: isDesktop ? 16 : 9,
      fields: 'list_headline,headline,published_at,link',
      include: isGameComparePage ? 'content' : 'image',
    }),
  ] });

  const MetaWrapper = isGameComparePage ? Page : EmptyWrapper;

  return (
    <>
      <Indent top={30} bottom={20}>
        <Rubric
          rawRubric={rawRubric}
          rawTopics={rawTopics}
          isLoading={rubricIsLoading || topicsAreLoading}
          limit={isDesktop ? 16 : 9}
          headerSpacing={isDesktop ? 30 : 20}
          titleComponent={({ children }) => (
            <WideWrapper>
              <H1 dataQa='page-title'>{children}</H1>
            </WideWrapper>
          )}
          skipRcm
          pagination={paginationProps => (
            <WideWrapper>
              <Pagination {...paginationProps} />
            </WideWrapper>
          )}
        >
          {({ topics }) => (
            <MetaWrapper
              title='Противостояние «Кто кого?»'
              description='Какой Ганнибал Лектор лучше, Паддингтон или Винни Пух, какой фильм о Гарри Поттере лучший. Эти и другие противостояния на сайте OKKOLOKINO.'
              skipRcm
            >
              <RubricTopics
                topics={topics}
                isLoading={topicsAreLoading}
                isGameComparePage={isGameComparePage}
              />
            </MetaWrapper>
          )}
        </Rubric>
      </Indent>
      <Boroda />
    </>
  );
}

RubricPage.propTypes = {
  isDesktop: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
};

export default withBreakpoint(RubricPage);
