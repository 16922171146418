import useRequest from 'core/hooks/useRequest';

import { topicQuery } from 'core/queries/topic';
import { topicsQuery } from 'core/queries/topics';

import { VOID_QUERY } from 'core/site/constants';

import topicRelationships from './topicRelationships';


export default function useTopicWithMovies(props) {
  const {
    data: topic,
    isLoading: topicIsLoading,
  } = useRequest(topicQuery(props));

  const { widgets } = topicRelationships(topic).content;

  const teaserTopicIds = widgets
    .filter(widget => widget.type === 'smartTeaser')
    .map(teaser => teaser.attributes.teaser_params.topic_id);

  const queryParams = !!teaserTopicIds?.length
    ? topicsQuery({
      topic_type: 'movie',
      fields: 'headline,source_url',
      include: 'image,movie_meta',
      ids: teaserTopicIds.join(','),
      renderError: () => [],
    })
    : VOID_QUERY;

  const {
    data: movies = [],
    isLoading: moviesAreLoading,
  } = useRequest(queryParams);

  const positionSyncedMovies = teaserTopicIds?.map(id => {
    return movies.find(movie => id === movie.id) || null;
  });

  return {
    isLoading: topicIsLoading || moviesAreLoading,
    topicWithMovies: {
      topic,
      movies: positionSyncedMovies,
    },
  };
}
