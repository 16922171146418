import { Fragment, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { bebop as bebopApi } from 'core/api/definitions/bebop';

import { denormalizeData } from 'core/utils/api';
import { getEnv, resolve } from 'core/utils/env';

import H3 from 'core/components/H3';
import Scroller from 'core/components/Scroller';
import { Indent } from 'core/components/Wrappers';
import { withBreakpoint } from 'core/components/breakpoint';
import ArrowRightRound from 'core/components/Icon/ArrowRightRound.svg';

import Collections from '../Collections';
import Afisha from '../Afisha';
import Lists from '../Lists';
import TvChannels from '../TvChannels';
import Skeleton from '../Skeleton';

import {
  CardExternalMovieType6,
  CardExternalMovieType7,
} from 'site/cards/CardExternalMovie';

import { host } from 'site/constants';

import styles from '../index.styl';


const externalBebopApis = {
  afisha: resolve({
    '*': `https://${getEnv('SITE_HOST') || host}/external/afisha/okkolokino`,
    'dev': 'https://s2.blog.okko.tv/external/afisha/okkolokino',
  }),
  okko: resolve({
    '*': `https://${getEnv('SITE_HOST') || host}/external/okko/main`,
    'dev': 'https://s2.blog.okko.tv/external/okko/main',
  }),
};

const queryGenerator = (type, queryParams, defaultValue) => ({
  queryKey: ['bebopApi', 'getExternal', externalBebopApis[type]],
  queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params, {}).catch(() => defaultValue),
  enabled: process.env.BROWSER_RUNTIME,
  ...queryParams,
});

function Render({ compactView, isDesktop }) {
  const [isOpened, setIsOpened] = useState(false);

  const { data: afisha = [], isLoading: afishaIsLoading } = useRequest(
    queryGenerator('afisha', { select: denormalizeData }, [])
  );

  const { data: okko = {}, isLoading: okkoIsLoading } = useRequest(
    queryGenerator('okko', {}, {})
  );

  if (afishaIsLoading || okkoIsLoading) return <Skeleton compactView={compactView} />;

  const {
    tvChannels,
    collections,
    movies,
  } = okko || {};

  const {
    shopPopulars,
  } = movies || {};

  const PopularMoviesCard = isDesktop ? CardExternalMovieType6 : CardExternalMovieType7;

  if (compactView) {
    return (
      <div className={cx(styles.compact, { [styles.opened]: isOpened })}>
        <Lists movies={movies} compactView={compactView} />
        <Indent top={30} />
        <Collections collections={collections} />
        {shopPopulars?.length && (
          <Fragment>
            <Indent top={30} />
            <H3>Магазин популярных фильмов</H3>
            <Indent top={15} />
            <Scroller listClassName={styles.cards}>
              {shopPopulars.map(item => (
                <div key={item.id} className={styles.card}>
                  <PopularMoviesCard content={item} />
                </div>
              ))}
            </Scroller>
            <Indent bottom={30} />
          </Fragment>
        )}
        <Indent top={30} />
        <Afisha afisha={afisha} />
        <Indent top={30} />
        <TvChannels tvChannels={tvChannels} />
        {!isOpened && (
          <div className={styles.fade}>
            <ArrowRightRound
              width={20}
              height={20}
              className={styles.arrowIcon}
              onClick={() => setIsOpened(true)}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <Fragment>
      <Collections collections={collections} />
      <Indent top={30} />
      <Afisha afisha={afisha} />
      <Indent top={30} />
      <Lists movies={movies} />
      <Indent top={30} />
      <TvChannels tvChannels={tvChannels} />
    </Fragment>
  );
}

Render.propTypes = {
  compactView: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(Render);
