import Hero from './Hero';
import AboutSeries from './AboutSeries';
import Dictionary from './Dictionary';
import Crossword from './Crossword';
import Advice from './Advice';
import Videos from './Videos';
import Samokat from './Samokat';
import Chastushki from './Chastushki';

import Sber from './images/Sber';

import styles from './index.styl';

export default function Mamonty() {
  return (
    <div className={styles.container}>
      <Hero />
      <AboutSeries />
      <Dictionary />
      <Crossword />
      <Advice />
      <Chastushki />
      <Samokat />
      <Videos />
      <div className={styles.partner}>
        <Sber />
        Генеральный партнёр
      </div>
    </div>
  );
}
