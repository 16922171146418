import PropTypes from 'prop-types';
import DictionaryItem from './DictionaryItem';

import cx from 'classnames';

import styles from './index.styl';

function DictionaryCard({ type, image, words }) {
  const isZoomerCard = type === 'zoomer';
  const header = isZoomerCard ? 'зумера' : 'бумера';
  const firstColumnLimit = isZoomerCard ? 9 : 10;
  return (
    <div className={cx(styles.dictionaryCardContainer, styles[`${type}Card`])} >
      <div className={styles.header}>Словарь {header}</div>
      <div className={styles.cardImageWrapper}>
        <img
          className={styles.cardImage}
          src={image}
          width={isZoomerCard ? '736' : '665'}
          height={isZoomerCard ? '842' : '765'}
          alt=''
        />
      </div>

      <dl className={styles.difinitionList}>
        <div className={styles.diffinionListColumn}>
          {words.slice(0, firstColumnLimit).map(({ word, definition, mark }) => (
            <DictionaryItem
              key={word}
              word={word}
              definition={definition}
              mark={mark}
            />
          ))}
        </div>
        <div className={styles.diffinionListColumn}>
          {words.slice(firstColumnLimit).map(({ word, definition, mark }) => (
            <DictionaryItem
              key={word}
              word={word}
              definition={definition}
              mark={mark}
            />
          ))}
        </div>
      </dl>

    </div>
  );
}

DictionaryCard.propTypes = {
  type: PropTypes.oneOf(['zoomer', 'boomer']),
  image: PropTypes.node,
  words: PropTypes.arrayOf(PropTypes.object),
};

export default DictionaryCard;
