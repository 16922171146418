import { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';


import MovieAvailabilityButton from 'site/components/MovieAvailabilityButton';
import { TopicContext } from 'site/pages/topic';

import styles from './index.styl';


export default function PageBreak(props) {
  const {
    block,
    parentProps,
  } = props;
  const {
    content,
    rubric,
    theme,
  } = parentProps;

  const { moviesData } = useContext(TopicContext);

  const { number, movie, hideNumber } = moviesData[block.id];
  const pageBreakIndex = content.findIndex(item => block.id === item.id);
  const isLastWidget = pageBreakIndex === content.length - 1;
  const isSelection = rubric.slug === 'selections';
  const showButton = !!(isSelection && movie);

  return (
    <div
      className={cx(
        styles.pageBreak,
        (isLastWidget || hideNumber) && styles._isLastWidget
      )}
    >
      {/*
        *Стили выносить не нужно,
        *компонент используется в AMP
      */}
      <style jsx>{`
        .${styles.pageBreak}
          position relative

          :global(.mobile) &
            padding-bottom 82px

          &:after
            content counter(pageBreakCount)
            counter-increment pageBreakCount
            position absolute
            z-index -1
            font-weight 700
            :global(.mobile) &
              left 50%
              transform translateX(-50%)
              bottom 0
              margin-bottom -72px
              font-size 128px
              line-height 166px
              z-index 0

      `}</style>
      <style jsx>{`
        .${styles.pageBreak}
          :global(.desktop) &
            border-bottom 1px solid ${theme.colors.divider}

          &.${styles._isLastWidget}
            border-bottom none
            &:after
              display none
          &:after
            color ${theme.colors.active1}
            font-family ${theme.fonts.display}
      `}</style>
      {showButton &&
        <div className={styles.button}>
          <MovieAvailabilityButton movie={movie} />
        </div>
      }
      <div className={styles.anchor} id={number} />
    </div>
  );
}

PageBreak.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
};
