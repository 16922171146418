import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { rawTopicsQuery } from 'core/queries/listPage';

import H1 from 'core/components/H1';
import Page from 'core/components/Page';
import ListPage from 'core/components/ListPage';
import { Indent, PageIndent } from 'core/components/Wrappers';
import { withBreakpoint } from 'core/components/breakpoint';

import RubricTopics from 'site/components/RubricTopics';
import { NarrowWrapper } from 'site/components/Wrappers';
import Pagination from 'site/components/Pagination';


const limit = 10;

const PaginationWithIndents = (props) => {
  return (
    <Indent top={20}>
      <Pagination {...props} />
    </Indent>
  );
};

function NewsPage(props) {
  const {
    location,
    history,
    isDesktop,
  } = props;

  const { data: rawData, isLoading } = useRequest(rawTopicsQuery({
    location,
    history,
    limit,
    topic_type: 'news',
    include: 'tags',
    fields: 'link,list_headline,headline,published_at',
  }));

  return (
    <Page>
      <PageIndent>
        <NarrowWrapper>
          <Indent top={isDesktop ? 0 : 20} bottom={isDesktop ? 20 : 10}>
            <H1>Новости</H1>
          </Indent>
          <ListPage
            rawData={rawData}
            title='Последние новости мира кино'
            description='Последние новости мира кино! Каждый день наше СМИ работает, чтобы вы получали свежие новости из мира кино и факты из жизни любимых актеров.'
            limit={limit}
            pagination={PaginationWithIndents}
            isLoading={isLoading}
            skipRcm
          >
            {({ content }) => (
              <RubricTopics
                topics={content}
                isLoading={isLoading}
                topicType='news'
              />
            )}
          </ListPage>
        </NarrowWrapper>
      </PageIndent>
    </Page>
  );
}

NewsPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(NewsPage);
