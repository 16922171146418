import useRequest from 'core/hooks/useRequest';

import { topicsQuery } from 'core/queries/topics';

import { bebop as bebopApi } from 'core/api/definitions/bebop';

import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';
import resolveRelationships from 'core/utils/relationships';

import CardMovie from 'site/cards/CardMovie';


const requiredPayloadImports = [
  'image',
  'read_more',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  read_more: {
    topic_ids: [],
  },
});

export default function useMainContent() {
  const results = useRequest({
    queries: [
      // wantToWatchSelections
      topicsQuery({
        list: 'recommendations',
        include: 'read_more,image',
        topic_type: 'selection',
        sort: 'list',
        fields: 'headline',
        renderError: () => [],
      }),

      // watchSelections
      topicsQuery({
        limit: 2,
        fields: 'headline,source_url',
        include: 'read_more',
        list: 'watch_selections',
        sort: 'list',
        renderError: () => [],
      }),

      // facts
      topicsQuery({
        list: 'facts',
        sort: 'list',
        topic_type: 'okko_fact',
        limit: 30,
        fields: 'announce,source',
        include: 'image',
        renderError: () => [],
      }),

      // gameCompare
      topicsQuery({
        limit: 1,
        list: 'contest',
        sort: 'list',
        include: 'content',
        fields: 'headline,list_headline,link,topic_type',
        renderError: () => [],
      }),

      // readingNow
      topicsQuery({
        sort: '-views_count',
        limit: 7,
        include: 'image,tags',
        fields: 'link,headline,published_at',
        visibility: 'main_page',
        days_period: 30,
        renderError: () => [],
      }),

      // tasty
      topicsQuery({
        list: 'tasty',
        sort: 'list',
        limit: 2,
        include: 'image',
        fields: 'link,headline,source_url',
        renderError: () => [],
      }),

      // tastyListTitle
      {
        queryKey: ['bebopApi', 'getList', {
          list_slug: 'tasty',
        }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params)
          .catch(() => null),
        select: data => denormalizeData(data).attributes.title,
      },

      // topical
      topicsQuery({
        list: 'topical',
        sort: 'list',
        limit: 2,
        include: 'image,tags',
        fields: 'link,headline,published_at,topic_type',
        renderError: () => [],
      }),

      // topicalListTitle
      {
        queryKey: ['bebopApi', 'getList', {
          list_slug: 'topical',
        }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params)
          .catch(() => null),
        select: data => denormalizeData(data).attributes.title,
      },

      // watchMovies
      topicsQuery({
        list: 'watch_movies',
        sort: 'list',
        limit: 4,
        card: CardMovie,
        renderError: () => [],
      }),

      // readNews
      topicsQuery({
        topic_type: 'news',
        sort: '-published_at',
        limit: 6,
        fields: 'link,list_headline,headline,published_at',
        include: 'tags',
        renderError: () => [],
      }),

      // readTopics
      topicsQuery({
        list: 'read',
        sort: 'list',
        limit: 2,
        fields: 'link,list_headline,headline,published_at',
        include: 'image,rubric',
        renderError: () => [],
      }),
    ],
  });

  const [
    { data: wantToWatchSelections, isFetched: wantToWatchSelectionsAreFetched },
    { data: watchSelections, isFetched: watchSelectionsAreFetched },
    ...otherResults
  ] = results;

  const {
    data: [
      facts,
      gameCompare,
      readingNow,
      tasty,
      tastyListTitle,
      topical,
      topicalListTitle,
      watchMovies,
      readNews,
      readTopics,
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(otherResults);

  const selectionsData = wantToWatchSelections?.map(selection => relationships(selection));
  const selectionsQueries = wantToWatchSelectionsAreFetched ? selectionsData?.map(selection => (
    topicsQuery({
      ids: selection.read_more.topic_ids,
      topic_type: 'movie',
      include: 'image',
      fields: 'source_url',
      renderError: () => [],
    })
  )) : [];
  const selectionsResults = [].concat(useRequest({ queries: selectionsQueries }) || {});

  const postersQueries = watchSelectionsAreFetched ? watchSelections?.map(selection => {
    const { topic_ids: topicIds } = relationships(selection).read_more;
    if (!topicIds.length) return null;

    return {
      queryKey: ['bebopApi', 'getTopics', {
        ids: topicIds,
        topic_type: 'movie',
        fields: 'headline',
        include: 'image',
      }],
      queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params)
        .then(denormalizeData)
        .then(topics => topics.map(topic => relationships(topic).image.versions.portrait))
        .catch(() => []),
    };
  }) : [];
  const postersResults = [].concat(useRequest({ queries: postersQueries }) || {});

  const wantToWatchSelectionsAreLoading = selectionsResults?.some(({ isLoading }) => isLoading);
  const watchSelectionsAreLoading = postersResults?.some(({ isLoading }) => isLoading);

  if (resultsAreLoading || wantToWatchSelectionsAreLoading || watchSelectionsAreLoading) return { isLoading: true };

  return {
    facts,
    gameCompare,
    readingNow,
    tasty,
    tastyListTitle,
    topical,
    topicalListTitle,
    watchMovies,
    readNews,
    readTopics,
    wantToWatchSelections: wantToWatchSelections?.map((selection, index) => ({
      ...selection,
      topics: selectionsResults?.[index]?.data,
    })),
    watchSelections: watchSelections?.map((selection, index) => ({
      ...selection,
      posters: postersResults?.[index]?.data,
    })),
  };
}

