import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { rawAuthorsQuery } from 'core/queries/authorsPage';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import AuthorsPage from 'core/components/AuthorsPage';
import H1 from 'core/components/H1';
import { PageIndent, Indent } from 'core/components/Wrappers';

import AuthorsPageCard from 'site/cards/CardAuthor/AuthorsPageCard';
import Boroda from 'site/components/Boroda';
import { NarrowWrapper } from 'site/components/Wrappers';


function Authors(props) {
  const { isMobile } = props;
  const { data: rawAuthors, isLoading } = useRequest(rawAuthorsQuery(props));

  return (
    <>
      <PageIndent>
        <NarrowWrapper>
          <Indent top={20} bottom={isMobile ? 20 : 30}>
            <H1>Авторы</H1>
          </Indent>
          <AuthorsPage
            rawAuthors={rawAuthors}
            isLoading={isLoading}
            card={AuthorsPageCard}
            columns={2}
            interitemSpacing={20}
          />
        </NarrowWrapper>
      </PageIndent>
      <Boroda />
    </>
  );
}

Authors.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Authors);
