import { Fragment, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import InfiniteTopic from 'core/components/InfiniteTopic';
import GameCompare from 'core/components/GameCompare';
import Topic from 'core/components/Topic';
import { Indent, PageIndent } from 'core/components/Wrappers';
import AdWrapper from 'core/components/Ad/AdWrapper';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { isCompareTopic as isCompareTopicCore } from 'core/components/GameCompare/utils';

import Boroda from 'site/components/Boroda';
import { NarrowWrapper } from 'site/components/Wrappers';
import { Inpage } from 'site/components/Ads';

import { SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

import { initTopicCtx } from 'site/utils';

import TopicContent from './TopicContent';
import TopicHeader from './TopicHeader';
import TopicFooter from './TopicFooter';
import TopicSkeleton from './Skeleton';
import Mascot from './Mascot';

import topicRelationships from './topicRelationships';
import useTopicWithMovies from './useTopicWithMovies';

import styles from './index.styl';


export const TopicContext = createContext({});

function TopicRender(props) {
  const {
    topic,
    movies,
    isMobile,
    withInfinityScroll,
  } = props;

  const {
    attributes: {
      topic_type: topicType,
      is_has_contents: isHasContents,
    },
  } = topic;

  const {
    rubric,
    content: {
      widgets: initialWidgets,
    },
    background: {
      asset: {
        attributes: {
          versions: {
            original: backgroundImage,
          },
        },
      },
    },
  } = topicRelationships(topic);

  const topicContext = useMemo(
    () => initTopicCtx(initialWidgets, rubric.slug, movies),
    [initialWidgets, rubric.slug, movies]
  );

  const isQuiz = topicType === 'quiz';
  const isCompareTopic = isCompareTopicCore(topicType);

  const WidthWrapper = isQuiz ? Fragment : NarrowWrapper;

  return (
    <TopicContext.Provider value={topicContext}>
      <PageIndent>
        <div className={styles.topicWrapper}>
          {!isMobile && <Mascot />}
          <WidthWrapper>
            {!isQuiz && (
              <TopicHeader content={topic} isCompareTopic={isCompareTopic} />
            )}
            {!isCompareTopic && (
              <>
                <TopicContent
                  rubric={rubric}
                  backgroundImage={backgroundImage}
                  isHasContents={isHasContents}
                  isQuiz={isQuiz}
                  topic={topic}
                />
                <TopicFooter content={topic}  isQuiz={isQuiz} />
              </>
            )}
            {isCompareTopic && (
              <GameCompare content={topic} />
            )}
            <Indent bottom={isMobile ? 16 : 20}>
              <AdWrapper top={10}>
                <Inpage />
              </AdWrapper>
            </Indent>
          </WidthWrapper>
        </div>
        <Indent
          left={isMobile ? -SIDE_INDENT_MOBILE : -SIDE_INDENT}
          right={isMobile ? -SIDE_INDENT_MOBILE : -SIDE_INDENT}
          {...withInfinityScroll && {
            bottom: isMobile ? 20 : 40,
            top: isMobile ? 0 : 9,
          }}
          {...!withInfinityScroll && {
            top: isMobile ? 83 : 55,
          }}
        >
          <Boroda topic={topic} onlyMovies={withInfinityScroll} />
        </Indent>
      </PageIndent>
    </TopicContext.Provider>
  );
}

TopicRender.propTypes = {
  topic: modelPropTypes(topicAttributes).isRequired,
  movies: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  moviesAreLoading: PropTypes.bool,
  enhancedTopic: PropTypes.shape({
    infinityIndex: PropTypes.number,
  }),
  withInfinityScroll: PropTypes.bool,
};

const EnhancedTopicRender = withBreakpoint(TopicRender);

function TopicPage(props) {
  const {
    topicWithMovies: {
      topic,
      movies,
    },
    isLoading,
  } = useTopicWithMovies(props);

  if (isLoading) return <TopicSkeleton />;

  const withInfinityScroll = topic.attributes.topic_type === 'news';

  const TopicWrapper = withInfinityScroll ? InfiniteTopic : Topic;

  return (
    <TopicWrapper content={topic} {...withInfinityScroll && { topic_type: 'news' }}>
      {(topicContent, enhancedTopic) => (
        <EnhancedTopicRender
          topic={topicContent}
          movies={movies}
          enhancedTopic={enhancedTopic}
          withInfinityScroll={withInfinityScroll}
        />
      )}
    </TopicWrapper>
  );
}

export default TopicPage;
