import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import Link from 'site/components/Link';

import { callYaMetrikaMethod } from 'core/utils/counters-helper';

import styles from './index.styl';

function PromoButton({ theme }, { counterIds }) {
  return (
    <Link
      to='https://okko.tv/serial/autsors?utm_source=blog&utm_medium=smm&utm_campaign=header-button'
      type='blank'
      className={cx(styles.link, styles[theme.controls.promoButton.type])}
      onClick={() => callYaMetrikaMethod(counterIds.yandexMetrika, 'reachGoal', 'header-button')}
    >
      <div className={styles.border} />
    </Link>
  );
}

PromoButton.propTypes = {
  theme: PropTypes.object,
};

PromoButton.contextTypes = {
  counterIds: PropTypes.object,
};

export default withTheme(PromoButton);
