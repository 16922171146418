import PropTypes from 'prop-types';

import Avatar from 'core/components/Avatar';
import Social from 'core/components/Social';
import H1 from 'core/components/H1';

import modelPropTypes, { personAttributes } from 'core/utils/prop-types/model';
import withTheme from 'core/components/theme';
import Link from 'core/components/Link';
import { PageIndent } from 'core/components/Wrappers';

import { NarrowWrapper } from 'site/components/Wrappers';

import styles from './index.styl';

const requiredPayloadFields = [
  'name',
  'slug',
  'job_title',
];

function AuthorPageCard(props) {
  const {
    content,
    author,
    theme: {
      controls: {
        authorPage: authorPageAtoms,
      },
      texts: textsAtoms,
    },
    allAuthorsLink,
  } = props;

  if (!(content || author)) return null;

  const {
    attributes: {
      avatar,
      biography,
      job_title: jobTitle,
      name,
      social_networks: socialNetworks,
    },
  } = content || author;

  const avatarSrc = avatar && avatar.attributes.versions.original;

  return (
    <PageIndent>
      <NarrowWrapper>
        <div className={styles.authorCard}>
          <style jsx>{`
            .${styles.authorCard} :global(.${styles.name})
              font ${authorPageAtoms.heading.font}

            .${styles.job}
              font ${textsAtoms.body.font}
              color ${textsAtoms.bodySmall.color}

            .${styles.allAuthorsLink}
              font ${textsAtoms.bodySmall.font}

            .${styles.biography}
              font ${textsAtoms.body.font}
            `}</style>

          <div className={styles.headingWrapper}>
            <div className={styles.avatar}>
              <Avatar
                src={avatarSrc}
                size={60}
                isCircular
              />
            </div>
            <div className={styles.heading}>
              <H1 className={styles.name}>{name}</H1>
              {!!jobTitle && <div className={styles.job}>{jobTitle}</div>}
            </div>
            {allAuthorsLink && (
              <div className={styles.allAuthorsLink}>
                <Link to={allAuthorsLink} type='secondary'>Все авторы</Link>
              </div>
            )}
          </div>

          {!!biography && <div className={styles.biography}>{biography}</div>}

          {socialNetworks?.length > 0 && (
            <div className={styles.socialNetworks}>
              <Social socials={socialNetworks} />
            </div>
          )}
        </div>
      </NarrowWrapper>
    </PageIndent>
  );
}

AuthorPageCard.propTypes = {
  /** Данные автора, соответствующие модели `person` */
  content: modelPropTypes(personAttributes),
  /** Данные автора, соответствующие модели `person` */
  author: modelPropTypes(personAttributes),
  /** @ignore */
  theme: PropTypes.object,
  /** Ссылка на страницу со списком всех авторов */
  allAuthorsLink: PropTypes.string,
};

AuthorPageCard.requiredPayloadFields = requiredPayloadFields;

export { AuthorPageCard as StorybookComponent };

export default withTheme(AuthorPageCard);
