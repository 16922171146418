import { Fragment } from 'react';
import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import Lead from 'core/components/Lead';
import H1 from 'core/components/H1';
import TopicTopline from 'core/components/TopicTopline';
import { Indent } from 'core/components/Wrappers';

import Socializator from 'site/components/Socializator';

import TopicHeaderImage from '../TopicHeaderImage';


export default function TopicHeader(props) {
  const {
    content,
    isCompareTopic,
  } = props;

  const {
    attributes: {
      announce,
      headline,
    },
  } = content;

  return (
    <Fragment>
      <TopicTopline hideFontSizeChanger isShowAuthors />
      <Indent top={10} />
      <H1>{headline}</H1>
      <Indent top={20} bottom={20}>
        <Lead>{announce}</Lead>
      </Indent>
      {!isCompareTopic && <Socializator />}
      {!isCompareTopic && <TopicHeaderImage content={content} />}
    </Fragment>
  );
}

TopicHeader.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  isCompareTopic: PropTypes.bool,
};
