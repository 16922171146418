import PropTypes from 'prop-types';
import cx from 'classnames';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withoutSSR from 'core/components/withoutSSR';

import ThemeSwitch from 'core/components/ThemeSwitch';

import WatchSuggestions from 'site/components/WatchSuggestions';

import BorodaTopics from './BorodaTopics';


function Boroda(props) {
  const {
    theme,
    isMobile,
    onlyMovies,
    topic,
  } = props;

  return (
    <div
      className={cx(
        'boroda',
        !onlyMovies && '_showTop',
      )}
    >
      <style jsx>{`
        .boroda._showTop
          border-top 1px solid ${theme.colors.divider}
          padding-top ${isMobile ? 30 : 40}px
      `}</style>
      {!onlyMovies && <BorodaTopics topic={topic} />}
      <ThemeSwitch name='dark'>
        <WatchSuggestions compactView />
      </ThemeSwitch>
    </div>
  );
}

Boroda.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  isMobile: PropTypes.bool,

  /** Отображать только сервисный блок */
  onlyMovies: PropTypes.bool,
  topic: modelPropTypes(topicAttributes),
};

const BorodaWithHOCs = withoutSSR(withBreakpoint(withTheme(Boroda)));

BorodaWithHOCs.displayName = 'Boroda';

export default BorodaWithHOCs;

export { Boroda as StorybookComponent };
