import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import bindProps from 'core/components/bindProps';

import Feed from 'core/components/Feed';
import PageLoader from 'core/components/Loader/PageLoader';
import Divider from 'core/components/Divider';
import { Indent } from 'core/components/Wrappers';

import PublicationsFeed from 'site/components/PublicationsFeed';
import ListDivider from 'site/components/ListDivider';

import CardHorizontal from 'site/cards/CardHorizontal';


const cardIndentDesktop = 20;
const cardIndentMobile = 15;
const NewsCardDesktop = bindProps({
  card: CardHorizontal,
  indent: cardIndentDesktop,
  size: 'm',
})(ListDivider);
const NewsCardMobile = bindProps({
  card: CardHorizontal,
  indent: cardIndentMobile,
  size: 'xs',
})(ListDivider);

function RubricTopics(props) {
  const {
    topics,
    isDesktop,
    isLoading,
    topicType,
    isGameComparePage,
  } = props;

  if (isLoading) return <PageLoader />;
  if (topicType === 'news' && !!topics?.length) {
    return (
      <Indent top={isDesktop ? 30 : 20}>
        <Feed
          content={topics}
          interitemSpacing={0}
          card={isDesktop ? NewsCardDesktop : NewsCardMobile}
        />
        <Indent top={isDesktop ? cardIndentDesktop : cardIndentMobile} />
        <Divider />
      </Indent>
    );
  }

  return (
    <PublicationsFeed
      content={topics}
      compareRubric={isGameComparePage}
    />
  );
}

RubricTopics.propTypes = {
  topics: PropTypes.array,
  isDesktop: PropTypes.bool,
  topicType: PropTypes.string,
  isGameComparePage: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default withBreakpoint(RubricTopics);
