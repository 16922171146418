import PropTypes from 'prop-types';

import Button from 'core/components/Button';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes from 'core/utils/prop-types/model';
import cutDown from 'core/utils/cut-down';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import SmartImage from 'core/components/SmartImage';
import Link from 'core/components/Link';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'source_url',
  'headline',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
});


function CardTasty(props) {
  const {
    content,
    imageMaxWidth,
    theme,
    isMobile,
  } = props;

  if (!content) return null;

  const {
    link,
    source_url: sourceUrl,
    headline,
    list_headline: listHeadline,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: originalCover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const text = isMobile
    ? listHeadline || headline
    : cutDown(listHeadline || headline, 88, true);
  const buttonText = sourceUrl ? 'Смотреть' : 'Читать';
  const buttonType = sourceUrl ? 'primary' : 'secondary';


  return (
    <Link
      to={sourceUrl || link}
      className={styles.link}
      type='secondary'
    >
      <style jsx>{`
        :global(.mobile .${styles.link})
          max-width ${imageMaxWidth}px

        .${styles.img}
          max-width ${imageMaxWidth}px

        .${styles.body}
          color ${theme.colors.primary}

        .${styles.headline}
          font-family ${theme.fonts.text}
          :global(.${styles.link}:hover) &
            color ${theme.colors.hoverHeadlineColor}
        `}</style>
      {originalCover && (
        <div className={styles.img}>
          <SmartImage
            src={originalCover}
            previewSrc={previewCover}
            maxWidth={imageMaxWidth}
            aspectRatio={1.5}
          />
        </div>
      )}
      <div className={styles.body}>
        <div className={styles.headline}>
          {text}
        </div>
        <Button className={styles.button} type={buttonType}>
          <span className={styles.buttonText}>{buttonText}</span>
        </Button>
      </div>
    </Link>
  );
}

CardTasty.propTypes = {
  /** Данные карточки */
  content: modelPropTypes(PropTypes.shape({
    link: PropTypes.string,
    source_url: PropTypes.string,
    headline: PropTypes.string,
    list_headline: PropTypes.string,
  })),
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Устанавливает максимальную ширину изображения */
  imageMaxWidth: PropTypes.number.isRequired,
};

const Card = withBreakpoint(withTheme(CardTasty));

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;


export default Card;

export { CardTasty as StorybookComponent };
