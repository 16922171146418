import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { topicsQuery } from 'core/queries/topics';

import bindProps from 'core/components/bindProps';
import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import GridFeed from 'core/components/GridFeed';
import EmptyWrapper from 'core/components/EmptyWrapper';
import Scroller from 'core/components/Scroller';
import WideWrapper from 'site/components/Wrappers/WideWrapper';
import Mascot from 'site/components/Mascot';

import CardPlaylist from 'site/cards/CardPlaylist';

import Skeleton from './Skeleton';

import styles from './index.styl';


const cardMobile = bindProps({ imageMaxWidth: 276, imageRatio: 2 })(CardPlaylist);
const cardDesktop = bindProps({ imageMaxWidth: 324, imageRatio: 2.3 })(CardPlaylist);

function Playlist({ theme, isMobile }) {
  const { data: playlists = [], isLoading } = useRequest(topicsQuery({
    limit: 4,
    card: CardPlaylist,
    topic_type: 'playlist',
    list: 'playlists',
    sort: 'list',
    renderError: () => [],
  }, { enabled: process.env.BROWSER_RUNTIME }));

  const content = playlists?.filter(i => i.attributes?.source_url);

  if (isLoading) return <Skeleton />;
  if (!content?.length) return null;

  const cardWidth = isMobile ? '276px' : '1fr';
  const templateColumns = Array(content.length).fill(cardWidth).join(' ');
  const OuterWrapper = isMobile
    ? Scroller
    : WideWrapper;
  const InnerWrapper = isMobile
    ? WideWrapper
    : EmptyWrapper;

  return (
    <div className={styles.wrapper}>
      <style jsx>{`
        .${styles.wrapper}
          background-color ${theme.colors.playlistBgColor}
          @media screen and (max-width: ${theme.layout.minWidth})
            min-width ${theme.layout.minWidth}
            margin-left 0
            left 0
      `}</style>

      <OuterWrapper>
        {!isMobile && (
          <div className={styles.mascotWrapper}>
            <Mascot
              className={styles.mascot}
              type='sitting'
              height={173}
            />
          </div>
        )}
        <InnerWrapper>
          <GridFeed
            content={content}
            gap={isMobile ? '10px' : '25px'}
            gridTemplateColumns={templateColumns}
            gridTemplateRows='1fr'
            card={isMobile ? cardMobile : cardDesktop}
          />
        </InnerWrapper>
      </OuterWrapper>
    </div>
  );
}

Playlist.propTypes = {
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(withTheme(Playlist));
