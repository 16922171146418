import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';

import modelPropTypes from 'core/utils/prop-types/model';

import GridFeed from 'core/components/GridFeed';
import { Indent } from 'core/components/Wrappers';
import { withBreakpoint } from 'core/components/breakpoint';

import CardHorizontal from 'site/cards/CardHorizontal';

const topicsModel = PropTypes.arrayOf(modelPropTypes(PropTypes.shape({
  link: PropTypes.string,
  list_headline: PropTypes.string,
  headline: PropTypes.string,
  published_at: PropTypes.string,
})));

const CardNews = bindProps({
  size: 'xs',
})(CardHorizontal);

const CardTopicsMobile = bindProps({
  size: 'xs',
  imageMaxWidth: 60,
  lockImageWidth: true,
  imageVersion: 'square',
})(CardHorizontal);

const CardTopicsDesktop = bindProps({
  size: 's',
  imageMaxWidth: 120,
  lockImageWidth: true,
  imageVersion: 'square',
})(CardHorizontal);

const gridTemplateColumns = 'repeat(2, 42.5%)';
const columnsGap = '35px';


function Read({ news, topics, isMobile }) {
  if (!news?.length && !topics?.length) return null;

  const newsFeedProps = isMobile
    ? { gap: '15px 0' }
    : {
      gap: `25px ${columnsGap}`,
      gridTemplateColumns,
    };

  const topicsFeedProps = isMobile
    ? { gap: '20px 0', card: CardTopicsMobile }
    : { gridTemplateColumns, gap: `0 ${columnsGap}`, card: CardTopicsDesktop };

  return (
    <div className='read'>
      <GridFeed
        card={CardNews}
        content={news}
        {...newsFeedProps}
      />
      <Indent bottom={isMobile ? 30 : 40} />
      <GridFeed
        content={topics}
        {...topicsFeedProps}
      />
    </div>
  );
}

Read.propTypes = {
  news: topicsModel,
  topics: topicsModel,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Read);
