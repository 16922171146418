import useRequest from 'core/hooks/useRequest';

import {
  authorQuery,
  topicsQuery,
} from 'core/queries/authorPage';

import PageLoader from 'core/components/Loader/PageLoader';

import AuthorPage from 'core/components/AuthorPage';

import AuthorPageCard from 'site/cards/CardAuthor/AuthorPageCard';

import PublicationsFeed from 'site/components/PublicationsFeed';
import Pagination from 'site/components/Pagination';
import { WideWrapper } from 'site/components/Wrappers';


const descriptionBuilder = author => 'Профиль автора. ' + (author?.attributes?.name || '');
const pagination = props => (
  <WideWrapper>
    <Pagination {...props} />
  </WideWrapper>
);

export default function Author(props) {
  const results = useRequest({ queries: [
    authorQuery(props),
    topicsQuery({
      ...props,
      limit: 16,
      include: 'rubric,image',
      fields: 'link,headline,published_at',
    }),
  ] });
  const [
    { data: author, isLoading: authorIsLoading },
    { data: topics, isLoading: topicsAreLoading },
  ] = results;

  return (
    <>
      {authorIsLoading && <PageLoader />}
      {!authorIsLoading &&
        <AuthorPage
          topics={topics}
          author={author}
          isLoading={topicsAreLoading}
          feedRenderer={PublicationsFeed}
          interitemSpacing={20}
          descriptionBuilder={descriptionBuilder}
          pagination={pagination}
          authorCard={AuthorPageCard}
          hideTopicsTitle
        />
      }
    </>
  );
}
