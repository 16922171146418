import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { topicsQuery } from 'core/queries/topics';

import { withBreakpoint } from 'core/components/breakpoint';
import bindProps from 'core/components/bindProps';

import ErrorPage from 'core/components/ErrorPage';
import Popular from 'core/components/Popular';
import PageLoader from 'core/components/Loader/PageLoader';
import GridFeed from 'core/components/GridFeed';
import { Indent } from 'core/components/Wrappers';

import { WideWrapper } from 'site/components/Wrappers';

import CardVertical from 'site/cards/CardVertical';
import CardHorizontal from 'site/cards/CardHorizontal';

const MobileCard = bindProps({
  size: 'xs',
  imageMaxWidth: 60,
  lockImageWidth: true,
})(CardHorizontal);
const DesktopCard = bindProps({
  size: 's',
  imageMaxWidth: 320,
})(CardVertical);


function NotFound({ isMobile }) {
  const { data: content, isLoading } = useRequest(
    topicsQuery({
      limit: 4,
      include: 'image,tags',
      fields: 'headline,list_headline,published_at,link',
      renderError: () => [],
    })
  );

  return (
    <WideWrapper>
      <ErrorPage
        type='notFound'
      />
      <Indent top={isMobile ? 80 : 150} />
      {isLoading && <PageLoader />}
      {!isLoading && (
        <Popular
          renderer={GridFeed}
          topics={content}
          card={isMobile ? MobileCard : DesktopCard}
          gridTemplateColumns={isMobile ? 1 : 'repeat(4, 1fr)'}
          gap={isMobile ? '20px' : '30px'}
        />
      )}
    </WideWrapper>
  );
}

NotFound.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(NotFound);
